<template>
  <v-container>
    <h5 class="subtitle-1 mb-4">Conclusão da implantação</h5>

    <v-alert type="error" outlined prominent>
      <div class="text-h6">Atenção!</div>
      <div>
        A partir da conclusão da implantação todas as interações com o cliente
        implantado serão realizadas pelos módulos de movimentação e fatura.
      </div>
    </v-alert>

    <v-divider class="my-5" />

    <v-row dense>
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />
        <BaseButton color="primary" title="Concluir" @click="finalize" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage, confirmMessage } from '@/helpers/messages';
import { ImplementationService } from '@/services/api/implementation';

export default {
  mixins: [rulesMixin],

  props: {
    implementationId: {
      type: String,
      require: true
    }
  },

  methods: {
    async finalize() {
      const result = await confirmMessage(
        'Você confirma a conclusão da implantação?',
        'A fatura base será gerada a partir das datas definidas na aba 1º fatura.'
      );

      if (result) {
        try {
          const implementationService = new ImplementationService();
          const { status } = await implementationService.finalizeImplementation(
            this.implementationId
          );

          if (status === 204) {
            showMessage('success', 'Implantação concluída com sucesso!');
            this.back();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    back() {
      this.$emit('back');
    }
  }
};
</script>
